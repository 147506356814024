<template>
  <div class="course-content">
    <!-- 头部 -->
    <div class="header">
      <div class="header-container">
        <div class="back">
          <img
            src="../../assets/back-left.png"
            alt=""
            style="width: 30px; height: 30px"
            @click="goBack()"
          />
        </div>
        <div class="header-name">
          {{ videoInfo.name }}
        </div>
      </div>
    </div>
    <!-- 播放视频区域 -->
    <div class="main">
      <div class="left">
        <div id="player-con" class="left-player"></div>
        <div class="left-name">{{ videoInfo.name }}</div>
        <div
          v-if="videoInfo.desc != null"
          class="left-bottom"
          v-html="videoInfo.desc"
        ></div>
      </div>
      <div class="right">
        <div class="row-name">视频目录</div>
        <div class="line"></div>
        <div
          class="right-list"
          v-for="(item, index) in hotVideos"
          :key="index"
          @click="viewVideo(item)"
        >
          <img
            v-if="item.coverUrl != null"
            class="right-list-left"
            :src="item.coverUrl"
          />
          <img v-else src="../../assets/empty.png" class="right-list-left" />
          <div class="right-list-right">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  hotKnowledgePoints,
  getHotInfo,
  getHotVideoPlayAuth,
} from "@/api/course";
import Chapter from "@/components/chapter";
var _this;
export default {
  data() {
    return {
      id: 0,
      videoInfo: {},
      courseChapterList: [],
      classId: 0,
      hotVideos: [],
      page: 1,
    };
  },
  components: {
    Chapter,
  },
  mounted() {
    _this = this;
    _this.videoInfo = _this.$route.query;
    _this.hotKnowledgePoints();
    _this.getHotVideoPlayAuth(_this.videoInfo.id, 0);
  },
  methods: {
    viewVideo(item) {
      _this.getHotVideoPlayAuth(item.id, 0);
    },
    /**
     * 播放视频
     */
    getHotVideoPlayAuth(id, progress) {
      getHotVideoPlayAuth(id).then((res) => {
        if (res != undefined) {
          if (this.player != null) {
            // 先销毁再播放，否则视频会重叠
            this.player.dispose();
          }
          // 绑定id，播放视频
          this.player = new Aliplayer(
            {
              id: "player-con",
              vid: res.data.vid,
              playauth: res.data.playAuth,
              qualitySort: "asc",
              width: "100%",
              height: "100%",
              format: "m3u8",
              mediaType: "video",
              definition: "FD,LD", // 显示视频清晰度(流畅、标清)
              defaultDefinition: "FD", // 默认视频清晰度
              disableSeek: false, // 禁用进度条仅Flash支持。
              autoplay: true,
              isLive: false, // 是否直播,直播状态开启才能禁止进度条
              rePlay: false,
              playsinline: true,
              preload: true,
              controlBarVisibility: "hover",
              useH5Prism: true,
              cover: _this.cover,
            },
            function (player) {
              // 相关api查看：https://help.aliyun.com/document_detail/125572.htm?spm=a2c4g.11186623.0.0.797710e4KeHz9o#task-1997027
              // 监听播放事件
              var seeked = false;
              player.on("canplaythrough", function (e) {
                if (!seeked) {
                  seeked = true;
                  // 设置播放器进度
                  player.seek(progress == undefined ? 0 : progress);
                }
              });
              player.on("playing", function () {
                // console.log("正在播放");
              });
              player.on("ended", function () {
                // console.log("播放完毕");
              });
            }
          );
        }
      });
    },
    hotKnowledgePoints() {
      hotKnowledgePoints({
        courseId: this.selectCourseId,
        page: this.page,
        limit: 5,
      }).then((res) => {
        if (res.code == 200) {
          this.hotVideos = res.data.list;
        }
      });
    },
    //返回上一页
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.course-content {
  background-color: #f4f5f8;
  .header {
    width: 100%;
    height: 100px;
    background-color: #fff;
    .header-container {
      display: flex;
      align-content: center;
      margin: 0 3vw;
      height: 100%;
      font-size: 20px;
      font-weight: 400;
      line-height: 100px;
      cursor: pointer;
      .header-name{
        margin-left: 20px;
      }
      .back {
        img {
          position: relative;
          transform: translate(0, 20%);
        }
      }
    }
  }
}
.main {
  display: flex; 
  margin: 20px;
  height: calc(80vh);
  .left {
    width: calc(100vw - 25vw - 10px);
    .left-player {
      width: 100%;
      border-radius: 5px 5px 0px 0px;
    }
    .left-name {
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      padding: 20px 0 20px 30px;
      background-color: white;
    }
    .left-bottom {
      padding: 20px 0 0px 30px;
    }
  }
  .right {
    margin-left: 10px;
    width: 25vw;
    height: calc(80vh);
    background-color: #fff;
    padding: 20px;
    overflow: hidden;
    overflow-y: scroll;
    .row-name {
      font-size: 20px;
      font-weight: 400;
    }
    .line {
      height: 5px;
      width: 60px;
      margin: 4px 0 30px 0;
      background-color: #4394ff;
      border-radius: 5px;
    }
    .right-list {
      height: 125px;
      display: flex;
      margin-bottom: 20px;
      cursor: pointer;
      .right-list-left {
        width: 200px;
        height: 120px;
        border-radius: 5px;
      }
      .right-list-right {
        width: 200px;
        height: 56px;
        font-size: 20px;
        color: #000000;
        margin-left: 20px;
      }
    }
  }
}
</style>